<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'my-profile' }">My Profile</router-link>
        </li>
        <li class="breadcrumb-item active">Edit</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Edit Profile</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link
            :to="{ name: 'my-profile' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                  <flash-message class="myCustomClass"></flash-message>
              </div>
              <div class="card-body">
                
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">First Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.firstname.$model"
                        :validator="$v.form.firstname"
                      />
                      <span v-if="$v.form.firstname.$error == true" class="error"> First Name is required</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Middle Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.mi.$model"
                        :validator="$v.form.mi"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                      <label class="form-label">Last Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.lastname.$model"
                        :validator="$v.form.lastname"
                      />
                      <span v-if="$v.form.lastname.$error == true" class="error"> Last Name is required</span>
                </div>
                <div class="mb-3">
                  <label class="form-label">Gender </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.gender.$model"
                    :validator="$v.form.gender"
                  >
                    <option selected>Select Gender</option>
                    <option value="NOT SPECIFIED" selected>NOT SPECIFIED</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                  </select>
                  <span v-if="$v.form.gender.$error == true" class="error"> Please select gender</span>
                </div>
                <div class="mb-3">
                  <label class="form-label">Civil Status</label>
                  <select
                    class="form-select"
                    v-model="$v.form.civilstatus.$model" :validator="$v.form.civilstatus">
                    <option selected>Select CivilStatus</option>
                    <option value="UNMARRIED">UNMARRIED</option>
                    <option value="MARRIED">MARRIED</option>
                    <option value="WIDOWED">WIDOWED</option>
                    <option value="LEGALLY SEPARATED">LEGALLY SEPARATED</option>
                  </select>
                  
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="firstName" class="form-label"
                        >Email Address</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        v-model="$v.form.emailaddress.$model"
                        :validator="$v.form.emailaddress"
                        readonly
                      />
                      <span
                        class="error "
                        v-if="$v.form.emailaddress.$error == true"
                        >Email address is required</span
                      >
                      <span v-if="$v.form.emailaddress.email && !$v.form.emailaddress.email">Enter valid email address</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="middleName" class="form-label"
                        >Mobile number *</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="middleName"
                        v-model="$v.form.mobileno.$model"
                        :validator="$v.form.mobileno"
                      />
                      <span
                        class="error "
                        v-if="$v.form.mobileno.$error == true"
                        >Mobile number is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="firstName" class="form-label">Age</label>
                      <input
                        type="number"
                        class="form-control"
                        id="firstName"
                        v-model="$v.form.age.$model"
                        :validator="$v.form.age"
                        readonly
                      />
                      <span
                        class="error "
                        v-if="$v.form.age.$error == true"
                        >Age is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="middleName" class="form-label"
                        >Date of Birth *</label
                      >
                      <datepicker  @input="calculateAge" :format="customFormatter" placeholder="Select Date" class="form-control" v-model="$v.form.birthday.$model" :validator="$v.form.birthday"></datepicker>
                      <span
                        class="error "
                        v-if="$v.form.birthday.$error == true"
                        >Date of birth is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                      <label class="form-label">PAN Number *</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.pan.$model"
                        :validator="$v.form.pan"
                      />
                      <span v-if="$v.form.pan.$error == true" class="error">PAN is required</span>
                </div>
                <div class="mb-3">
                      <label class="form-label">Aadhar Number *</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.aadhar.$model"
                        :validator="$v.form.aadhar"
                      />
                      <span v-if="$v.form.aadhar.$error == true" class="error"> Aadhar number is required</span>
                </div>
                <div class="mb-3">
                      <label class="form-label">Home Address *</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="$v.form.homeaddress.$model"
                        :validator="$v.form.homeaddress"
                      />
                      <span v-if="$v.form.homeaddress.$error == true" class="error"> Home Address is required</span>
                </div>
                <div class="mb-3">
                  <input type="file" @change="uploadFile" ref="file" class="form-control" id="image">
                  <div v-if="this.form.profile_pic!=null" >
                    <img
                      class="avatar border-white"
                      style="height:100px !important; width:150px;"
                      :src="
                       this.$baseUrl+'/faces/'+this.form.profile_pic
                      "
                      alt="profile photo"
                    />
                  </div>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-outline-primary space" >Update</button>
                    <router-link :to="{name: 'my-profile'}" class="btn btn-outline-primary">Cancel</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import axios from "axios";
export default {
  components: { Datepicker },
  props: ["id"],
  data() {
    return {
      form: {
        firstname: null,
        mi: null,
        lastname: null,
        gender: null,
        civilstatus: null,
        height: null,
        weight: null,
        emailaddress: null,
        mobileno: null,
        age: null,
        birthday: null,
        pan: null,
        aadhar: null,
        homeaddress: null,
        profile_pic: null
      },
    };
  },
  validations: {
    form: {
        firstname: {
            required
        },
        mi: {
            
        },
        lastname: {
            required
        },
        gender: {
            required
        },
        civilstatus: {
            required
        },
        emailaddress: {
            required
        },
        mobileno: {
            required
        },
        age: {
            required
        },
        birthday: {
            required
        },
        pan: {
            required
        },
        aadhar: {
            required
        },
        homeaddress: {
            required
        },
    },
  },
  created() {
    this.$store.dispatch('switchLoader',true);
      this.$store.dispatch("loadMyProfile");
  },
  mounted() {
      
    this.form.firstname = this.$store.state.myProfileData.profile.firstname;
    this.form.mi = this.$store.state.myProfileData.profile.mi;
    this.form.lastname = this.$store.state.myProfileData.profile.lastname;
    this.form.gender = this.$store.state.myProfileData.profile.gender;
    this.form.civilstatus = this.$store.state.myProfileData.profile.civilstatus;
    this.form.height = this.$store.state.myProfileData.profile.height;
    this.form.weight = this.$store.state.myProfileData.profile.weight;
    this.form.emailaddress = this.$store.state.myProfileData.profile.emailaddress;
    this.form.mobileno = this.$store.state.myProfileData.profile.mobileno;
    this.form.age = this.$store.state.myProfileData.profile.age;
    this.form.birthday =  moment(this.$store.state.myProfileData.profile.birthday).format('YYYY-MM-DD');
    this.form.pan = this.$store.state.myProfileData.profile.pan;
    this.form.aadhar = this.$store.state.myProfileData.profile.aadhar;
    this.form.homeaddress = this.$store.state.myProfileData.profile.homeaddress;
    this.form.profile_pic = this.$store.state.myProfileData.profile.avatar;
    
   this.calculateAge();
  },
 
  methods: {
    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },
    uploadFile() {
        this.form.profile_pic = this.$refs.file.files[0];
        console.log(this.form.profile_pic);
      },
    calculateAge(){
       
      var today = new Date();
      var birthDate = new Date(this.form.birthday);
      console.log('birthday', moment(String(birthDate)).format('MMM DD YYYY'));
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      this.form.age =  age;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const formData = new FormData();
        formData.append('avatar', this.form.profile_pic);
        formData.append('firstname', this.form.firstname);
        formData.append('mi', this.form.mi);
        formData.append('lastname', this.form.lastname);
        formData.append('gender', this.form.gender);
        formData.append('civilstatus', this.form.civilstatus);
        formData.append('emailaddress', this.form.emailaddress);
        formData.append('mobileno', this.form.mobileno);
        formData.append('age', this.form.age);
       
        formData.append('birthday', moment(this.form.birthday).format('YYYY-MM-DD'));
        formData.append('pan', this.form.pan);
        formData.append('aadhar', this.form.aadhar);
        formData.append('homeaddress', this.form.homeaddress);
      axios
        .post("/personal/myprofile/update", formData)
        .catch(({ response }) => {
          console.log('response',response);
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          
          if (data.success) {
            console.log('updated');
            localStorage.setItem('avatar',this.form.profile_pic);
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "my-profile" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>